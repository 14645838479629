import { Routes, Route, Link } from "react-router-dom";
import './App.css';
import './components/Navigation.css';
import ScrollToTop from "./components/ScrollToTop"
import About from "./pages/about"
import Contact from "./pages/contact"
import Home from "./pages/home"
import Footer from "./pages/footer"
import Map from "./pages/map"
import MotorDrives from "./pages/motor-drives"
import ProductsAdditional from "./pages/products-additional"
import ProductsBanner from "./pages/products-banner"
import ProductsBusLevel from "./pages/products-bus-level"
import ProductsDanFoss from "./pages/products-danfoss"
import ProductsDynapar from "./pages/products-dynapar"
import ProductsMechanicalDrives from "./pages/products-mechanical-drives"
import ProductsMotorDrives from "./pages/products-motor-drives"
import ProductsMotors from "./pages/products-motors"
import ProductsRedLion from "./pages/products-redlion"
import ProductsSafety from "./pages/products-safety"
import ProductsSensors from "./pages/products-sensors"
import ProductsTurck from "./pages/products-turck"
import ProductsWago from "./pages/products-wago"
import ProductsWeg from "./pages/products-weg"
import Products from "./pages/products"
import Stripe from "./pages/stripe"

function App() {
  return (
    <div className="app-container">
      <nav className="main-navigation">
        <div className="nav-container">
          <Link to="/">Home</Link>
          <Link to="/about">About</Link>
          <Link to="/contact">Contact</Link>
          <Link to="/map">Map</Link>
          <Link to="/products">Products</Link>
          <Link to="/products-motors">Motors</Link>
          <Link to="/products-motor-drives">Motor Drives</Link>
          <Link to="/products-mechanical-drives">Mechanical Drives</Link>
          <Link to="/products-sensors">Sensors</Link>
          <Link to="/products-safety">Safety</Link>
        </div>
      </nav>
      <div className="body-wrapper">
        <ScrollToTop>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='about' element={<About />} />
            <Route path='contact' element={<Contact />} />
            <Route path='map' element={<Map />} />
            <Route path='motor-drives' element={<MotorDrives />} />
            <Route path='products-additional' element={<ProductsAdditional />} />
            <Route path='products-banner' element={<ProductsBanner />} />
            <Route path='products-bus-level' element={<ProductsBusLevel />} />
            <Route path='products-danfoss' element={<ProductsDanFoss />} />
            <Route path='products-dynapar' element={<ProductsDynapar />} />
            <Route path='products-mechanical-drives' element={<ProductsMechanicalDrives />} />
            <Route path='products-motor-drives' element={<ProductsMotorDrives />} />
            <Route path='products-motors' element={<ProductsMotors />} />
            <Route path='products-redlion' element={<ProductsRedLion />} />
            <Route path='products-safety' element={<ProductsSafety />} />
            <Route path='products-sensors' element={<ProductsSensors />} />
            <Route path='products-turck' element={<ProductsTurck />} />
            <Route path='products-wago' element={<ProductsWago />} />
            <Route path='products-weg' element={<ProductsWeg />} />
            <Route path='products' element={<Products />} />
            <Route path='stripe' element={<Stripe />} />
          </Routes>
        </ScrollToTop>
        <Footer />
      </div>
    </div>
  );
}

export default App;
